// http://tachyons.io/components/quotes/left-border/index.html
// https://daveceddia.com/usestate-hook-examples/

import React, { useState } from "react"
import { Row, Col } from "antd"
import Planning from "./planning"
import Owning from "./owning"
import Lifecycle from "./lifecycle"
import { FormattedMessage } from "gatsby-plugin-intl"
import "../../css/services.css";


const Services = () => {
    const [service, setService] = useState(true)

    return (
        <section id="services" className="pt5">
            <Row type="flex" justify="center">
                <Col span={24} className="tc">
                    <h1 className="tc text-title"><FormattedMessage id="menu.services" /></h1>
                    <hr />
                </Col>
            </Row>
            <Row type="flex" justify="center">
                <Col xs={22} sm={18} md={14} lg={12}>
                    <p className="mt5">
                        <span>
                            <FormattedMessage id="services.beginning" /> <a href="#lifecycle" className="u"><FormattedMessage id="services.lifecycle" /></a>, <FormattedMessage id="services.description1" />
                        </span>                        
                        <br />
                        <br />
                        <FormattedMessage id="services.description2" />
                        <br />
                        <br />
                        <FormattedMessage id="services.help" />
                    </p>
                </Col>
            </Row>

            <br />
            <div id="service-types">
                <Row type="flex" justify="center">
                    <Col xs={22} sm={18} md={14} lg={12}>
                        <Row type="flex" justify="space-between" gutter={20} className="mb4">
                            <Col span={12}>
                                <div className={service ? "service-btn-active": "service-btn"} onClick={() => setService(true)}><FormattedMessage id="services.planning.title" /></div>
                            </Col>
                            <Col span={12}>
                                <div className={service ? "service-btn" : "service-btn-active"} onClick={() => setService(false)}><FormattedMessage id="services.owning.title" /></div>
                            </Col>
                        </Row>
                        {service ? <Planning /> : <Owning />}
                    </Col>
                </Row>
            </div>

            <Lifecycle />

        </section>
    )
}

export default Services