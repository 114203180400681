import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import Landing from "../components/sections/landing"
import About from "../components/sections/about"
import Services from "../components/sections/services"
import Contact from "../components/sections/contact"
import icon from '../images/og-hotelis.png'


const IndexPage = () => (
  <Layout>
    <Helmet>
      <link rel="stylesheet" href="https://unpkg.com/tachyons@4.10.0/css/tachyons.min.css" />
      <link href="https://fonts.googleapis.com/css?family=Cabin&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css?family=Cabin|Lora&display=swap" rel="stylesheet"></link>
      <meta property="og:title" content="Hotelis" />
      <meta property='og:description' content="An independent consulting firm specializing in hotel and tourism investments." />
      <meta property="og:type" content="website" />
      <meta property='og:url' content='https://hotelis.hr/' />
      <meta property="og:image" content={icon} />
      <meta property='og:locale' content='en_US' />
    </Helmet>    
    <SEO title="Home" />
    <Landing />
    <About />
    <Services />
    <Contact />
  </Layout>
)

export default IndexPage