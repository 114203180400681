import React from "react"
import { Row, Col } from "antd"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import Table from "./lifecycleTable";
import "../../css/lifecycle.css"
import Pattern from "../../images/Pattern.svg";

const Lifecycle = ({ intl }) => {

    return (
        <section id="lifecycle" className="pt5 mt5">
            <header className="bg-lifecycle pv5">
                <Row type="flex" justify="center">
                    <Col span={18} className="tc pb6 pt3">
                        <h1 className="tc text-title white"><FormattedMessage id="lifecycle.title" /></h1>
                        <hr />
                        <p className="white mv4 lifecycle-text">
                            <FormattedMessage id="lifecycle.subtitle" />
                            <br />
                            <br />
                            <FormattedMessage id="lifecycle.description" />
                        </p>
                        <br />
                    </Col>
                </Row>
            </header>
            <div className="lifecycle-table bg-near-white">
                <Table />
                <br />
            </div>
            <div className="image-quote bg-near-white pb5">
                <img src={Pattern} alt="hotelis" width="100%" className="pv4" />
                <span className="tc db b f3 hotelis-dark-grey quote">
                    <FormattedMessage id="services.quote" />
                </span>
            </div>
        </section >
    )
}

export default injectIntl(Lifecycle)
