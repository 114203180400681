/**
 * diagonal backgrounds https://css-tricks.com/creating-non-rectangular-headers/
 */
import React from "react"
import { Row, Col, Icon } from "antd"
import { FormattedMessage } from 'gatsby-plugin-intl';
import reception from "../../images/hero-image.png";
import rics from "../../images/RICS-LOGO-GRAY.png";
import "../../css/landing.css";

//<FormattedMessage id="landing.header"/>
const Landing = () => (
    <section id="landing">
        <header className="pb3">
            <Row type="flex" justify="center" align="middle">
                <Col xs={{ span:24, order:2 }} sm={{ span:24, order:2 }} md={{ span:10, order:1 }} lg={{ span:10, order:1 }}>
                    <br/>
                    <div className="ml3">
                        <span className="b lh-copy tl white db landing-text">
                            <FormattedMessage id="landing.title"/>
                        </span>
                        <br/>
                        <div className="ml3">
                        <p className="subtitle-text f5 mv3 mr3">
                            <FormattedMessage id="landing.subtitle" />
                        </p>                                                
                        <a href="#about" className="f5 no-underline hotelis-aquamarin bg-animate hover-bg-white hotelis-link inline-flex items-center pv2 ph4 ba border-box mr4 ttu">
                            <span className="pl1"><FormattedMessage id="landing.button"/> <Icon type="arrow-down" /></span>
                        </a>
                        <p className="mt3">
                        <img src={rics} alt="rics" width="180"/>                        
                        </p>
                        </div>
                    </div>
                </Col>
                <Col xs={{ span:24, order:1 }} sm={{ span:24, order:2 }} md={{ span:10, order:1 }} lg={{ span:14, order: 1 }}>
                    <img src={reception} alt="hotelis reception"/>
                </Col>
            </Row>
        </header>
    </section>
)

export default Landing