// https://github.com/VincentGarreau/particles.js/issues/216
// https://www.npmjs.com/package/react-particles-js

import React from "react"
import silvija from "../../images/silvija.jpg"
import { Row, Col } from "antd"
import { FormattedMessage } from "gatsby-plugin-intl"
import "../../css/team.css"

const Team = () => (
    <section id="team" className="pt5">
        <Row type="flex" justify="center" className="bg-near-white">
            <Col xs={24} lg={22}>
                <Row type="flex" justify="center">
                    <Col xs={24} sm={22} md={12} lg={12} xl={10}>
                        <div className="tc">
                            <img src={silvija} alt="Silvija Ilišković Balagovic" />
                        </div>
                    </Col>
                    <Col xs={22} sm={22} md={12} lg={12} xl={14}>
                        <div className="bg-white shadow-4 pt5 pb4 nt3 nb3 team-card">
                            <div className="dib v-mid">
                                <br/>
                                <div className="card-spacer"></div>
                                <span className="team-title b db pb3 tl"><FormattedMessage id="about.team.title"/></span>
                                <strong className="team-subtitle tl"><FormattedMessage id="about.team.emphasys"/></strong>
                                <br/><br />
                                <div className="tj">
                                    <FormattedMessage id="about.team.biography"/>
                                </div>                                                                                                                       
                                <br/><br/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </section>
)

export default Team
