import React from "react"
import Team from "./team"
import { Row, Col } from "antd"
import { FormattedMessage } from "gatsby-plugin-intl"

const About = () => (
    <section id="about" className="pv5">
        <Row type="flex" justify="center">
            <Col span={24} className="tc">
                <h1 className="tc text-title"><FormattedMessage id="about.title"/></h1>
                <hr />
            </Col>
        </Row>
        <br />
        <Row type="flex" justify="center">
            <Col xs={20} sm={16} md={14} lg={12}>
                <p className="tj about-text">
                    <span className="headline"><FormattedMessage id="about.emphasys"/></span>
                    <br />
                    <br />
                    <FormattedMessage id="about.description1"/>
                    <br />
                    <br />
                    <FormattedMessage id="about.description2"/>
                    </p>
            </Col>
        </Row>
        <br/>
        <br/>
        <Team />
        <div className="bg-near-white">
            <div className="pv5">
                <h4 className="tc mt5 deliver-title"><FormattedMessage id="about.delivery.title"/></h4>
                <br />
                <Row type="flex" justify="space-around">
                    <Col xs={15} sm={10} md={4} lg={4} className="tc ph3 pt4 pb3 bb bw1 b--aquamarin">
                        <span className="db mb2 deliver-bullet f5">&#x25A0;</span>
                        <FormattedMessage id="about.delivery.experience"/>
                    </Col>
                    <Col xs={15} sm={10} md={4} lg={4} className="tc ph3 pt4 pb3 bb bw1 b--aquamarin">
                        <span className="db mb2 deliver-bullet f5">&#x25A0;</span>
                        <FormattedMessage id="about.delivery.methodology"/>
                    </Col>
                    <Col xs={15} sm={10} md={4} lg={4} className="tc ph3 pt4 pb3 bb bw1 b--aquamarin">
                        <span className="deliver-bullet db mb2 f5">&#x25A0;</span>
                        <FormattedMessage id="about.delivery.understanding"/>
                    </Col>
                    <Col xs={15} sm={10} md={4} lg={4} className="tc ph3 pt4 pb3 bb bw1 b--aquamarin">
                        <span className="deliver-bullet db mb2 f5">&#x25A0;</span>
                        <FormattedMessage id="about.delivery.knowledge"/>
                    </Col>
                    <Col xs={15} sm={10} md={4} lg={4} className="tc ph3 pt4 pb3 bb bw1 b--aquamarin">
                        <span className="deliver-bullet db mb2 f5">&#x25A0;</span>
                        <FormattedMessage id="about.delivery.approach"/>
                    </Col>
                </Row>
                <br/>
            </div>
        </div>
        <div className="pv5 ph3 bg-hotelis-aquamarin">
            <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={23} lg={23} xl={23}>
                    <div className="tc mv5 white">
                        <p className="quote-title"><FormattedMessage id="about.quote1"/> <br /> <FormattedMessage id="about.quote2"/></p>
                        <a href="#contact" className="f5 no-underline white bg-animate hover-bg-white white inline-flex items-center pv2 ph4 ba border-box ttu">
                            <span className="pl1"><FormattedMessage id="about.contact"/></span>
                        </a>
                    </div>
                </Col>
            </Row>
        </div>
    </section>
)

export default About
