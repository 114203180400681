import React from "react"
import TextToggle from "./textToggle"
import { FormattedMessage } from "gatsby-plugin-intl"

const Owning = () => {
    return (
        <>
            <div className="b lh-copy mb4">
                <FormattedMessage id="services.owning.description" />
            </div>

            <TextToggle
                title={<FormattedMessage id="services.owning.valuation" />}
                description={<FormattedMessage id="services.owning.valuation.desc" />}
            />

            <TextToggle
                title={<FormattedMessage id="services.owning.assessment" />}
                description={<FormattedMessage id="services.owning.assessment.desc" />}
            />
            <TextToggle
                title={<FormattedMessage id="services.owning.acquisition" />}
                description={<FormattedMessage id="services.owning.acquisition.desc" />}
            />
            <TextToggle
                title={<FormattedMessage id="services.owning.asset" />}
                description={<FormattedMessage id="services.owning.asset.desc" />}
            />

        </>
    )
}

export default Owning
