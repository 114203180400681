import React from "react"
import TextToggle from "./textToggle"
import { FormattedMessage } from "gatsby-plugin-intl"

const Planning = () => {
    return (
        <>
            <div className="b lh-copy mb4">
                <FormattedMessage id="services.planning.description" />
            </div>

            <TextToggle
                title={<FormattedMessage id="services.planning.market" />}
                description={<FormattedMessage id="services.planning.market.desc" />}
            />

            <TextToggle
                title={<FormattedMessage id="services.planning.analysis" />}
                description={<FormattedMessage id="services.planning.analysis.desc" />}
            />

            <TextToggle
                title={<FormattedMessage id="services.planning.brand" />}
                description={<FormattedMessage id="services.planning.brand.desc" />}
            />

            <TextToggle
                title={<FormattedMessage id="services.planning.support" />}
                description={<FormattedMessage id="services.planning.support.desc" />}
            />

            <TextToggle
                title={<FormattedMessage id="services.planning.preopening" />}
                description={<FormattedMessage id="services.planning.preopening.desc" />}
            />

        </>
    )
}

export default Planning