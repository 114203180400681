import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl'
import logo from "../../images/Logo_Footer.svg"
import map from "../../images/hotelis-map.jpg"
import "../../css/contact.css"

const Contact = () => (
    <>
        <footer id="contact" className="tc bg-hotelis-dark-grey pt5 pb2 white f7">
            <h1 className="passionate pb5 slogan"><FormattedMessage id="landing.slogan" /></h1>
            <div className="tc">
                <div className="ba line w-50 tc dib"></div>
            </div>
            <img src={logo} alt="hotelis" width="180" className="pv4" />
            <br />
            <div className="footer-nav pb4">
                <a href="#about" className="ma3 link no-underline hotelis-light-grey ttu"><FormattedMessage id="menu.about" /></a>
                <a href="#services" className="ma3 link no-underline hotelis-light-grey ttu"><FormattedMessage id="menu.services" /></a>
                <a href="#contact" className="ma3 link no-underline hotelis-light-grey ttu"><FormattedMessage id="menu.contact" /></a>
            </div>
            <span className="hotelis-light-grey">Zagreb Tower -  Instant Office, 5/8 | Radnička cesta 80, Zagreb</span>
            <span className="hotelis-aquamarin"> - </span><a href="tel:+385 99 23 27 637" className="hotelis-light-grey hover-white">+385 99 23 27 637</a> <span className="hotelis-aquamarin"> - </span>
            <a href="mailto:siliskovic@hotelis.hr" className="hotelis-light-grey hover-white">siliskovic@hotelis.hr</a>
            <br />
            <br />
            <br />
        </footer>
        <div className="google-maps">
            <a href="https://goo.gl/maps/36qjvmAwDnoWHo5y7" target="_blank" rel="noopener noreferrer">
                <img src={map} alt="Hotelis map" />
            </a>
            {/* <iframe src="https://snazzymaps.com/embed/202200" className="mb0 pb0" width="100%" height="200px" style={{ border:'none' }} title="Hotelis Map"></iframe> */}
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22249.415060203726!2d15.972714600133259!3d45.80771581279748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb0663670fbe4092!2sMADRIA%20international%20(office%20Zagreb%2C%20Croatia)!5e0!3m2!1sen!2shr!4v1574346703820!5m2!1sen!2shr" width="100%" height="150" className="mb0 pb0" frameBorder="0" style={{ 'border': 0, 'padding':0, 'margin':0 }} title="Hotelis Map" allowFullScreen></iframe> */}
        </div>

    </>

)


export default Contact;

