// https://codepen.io/robertkoons/pen/jEOZwz
// https://nivo.rocks/calendar/

import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Row, Col } from "antd"

const Table = ({ intl }) => (
    <Row type="flex" justify="center" >
        <Col span={22}>
            <Row type="flex" justify="center" className="hotelis-table">
                <Col xs={22} sm={12} md={8} lg={6} xl={6}>
                    <div className="step1 shadow-4">
                        <header className="step1-header ph2 tr step-header-text">
                            <span className="step"><FormattedMessage id="lifecycle.step" /></span>01
                        </header>
                        <div className="card-content step1-background pa3">
                            <h6 className="ttu cycle-title cycle-title-black"><FormattedMessage id="lifecycle.1.title" /></h6>
                            <ul className="list pl0">
                                <li><FormattedMessage id="lifecycle.1.keyword1" /></li>
                                <li><FormattedMessage id="lifecycle.1.keyword2" /></li>
                                <li><FormattedMessage id="lifecycle.1.keyword3" /></li>
                                <li><FormattedMessage id="lifecycle.1.keyword4" /></li>                                
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col xs={22} sm={12} md={8} lg={6} xl={6}>
                    <div className="step2 shadow-3">
                        <header className="step2-header ph2 tr step-header-text">
                            <span className="step 03"><FormattedMessage id="lifecycle.step" /></span><span className="o-70">02</span>
                        </header>
                        <div className="card-content step2-background pa3">
                            <h6 className="ttu"><FormattedMessage id="lifecycle.2.title" /></h6>
                            <ul className="list pl0">
                                <li><FormattedMessage id="lifecycle.2.keyword1" /></li>
                                <li><FormattedMessage id="lifecycle.2.keyword2" /></li>
                                <li><FormattedMessage id="lifecycle.2.keyword3" /></li>
                                <li><FormattedMessage id="lifecycle.2.keyword4" /></li>
                                <li><FormattedMessage id="lifecycle.2.keyword5" /></li>
                                <li><FormattedMessage id="lifecycle.2.keyword6" /></li>
                                <li><FormattedMessage id="lifecycle.2.keyword7" /></li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col xs={22} sm={12} md={8} lg={6} xl={6}>
                    <div className="step3 shadow-2">
                        <header className="step3-header ph2 tr step-header-text">
                            <span className="step o-70"><FormattedMessage id="lifecycle.step" /></span><span className="o-70">03</span>
                        </header>
                        <div className="card-content step3-background pa3">
                            <h6 className="ttu"><FormattedMessage id="lifecycle.3.title" /></h6>
                            <ul className="list pl0">
                                <li><FormattedMessage id="lifecycle.3.keyword1" /></li>
                                <li><FormattedMessage id="lifecycle.3.keyword2" /></li>
                                <li><FormattedMessage id="lifecycle.3.keyword3" /></li>
                                <li><FormattedMessage id="lifecycle.3.keyword4" /></li>
                                <li><FormattedMessage id="lifecycle.3.keyword5" /></li>
                                <li><FormattedMessage id="lifecycle.3.keyword6" /></li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col xs={22} sm={12} md={8} lg={6} xl={6}>
                    <div className="step4 shadow-1">
                        <header className="step4-header ph2 tr step-header-text">
                            <span className="step o-70"><FormattedMessage id="lifecycle.step" /></span><span className="o-70">04</span>
                        </header>
                        <div className="card-content step4-background pa3">
                            <h6 className="ttu"><FormattedMessage id="lifecycle.4.title" /></h6>
                            <ul className="list pl0">
                                <li><FormattedMessage id="lifecycle.4.keyword1" /></li>
                                <li><FormattedMessage id="lifecycle.4.keyword2" /></li>
                                <li><FormattedMessage id="lifecycle.4.keyword3" /></li>
                                <li><FormattedMessage id="lifecycle.4.keyword4" /></li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>
)

export default Table  
