import React, { useState } from "react"
import { Icon } from "antd"


const TextToggle = ({ title, description }) => {
    const [hidden, setHidden] = useState(true);    

    return (
        <>
            <div className={hidden ? "link pt2 gray bb ttu b points-titles line-divider" : "link pt2 gray bb ttu b points-titles line-divider hotelis-link"} onClick={() => setHidden(!hidden)}>
                {title}
                <span className="fr ttl hotelis-aquamarin">{hidden ? <Icon type="plus" /> : <Icon type="close" />}</span>
            </div>
            { hidden ? null : <p>{description}</p> }
        </>
    )
}

export default TextToggle